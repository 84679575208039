import React from "react";
import { StyledCenteredContainer } from "./styled";

const CenteredContainer = ({ children }) => {
    return (
        <StyledCenteredContainer>
            {children}
        </StyledCenteredContainer>
    )
}

export default CenteredContainer;